import * as Sentry from '@sentry/nextjs'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useState, createContext, useContext } from "react"

import { queries } from "../services/api"

export const PreferedCurrencyContext = createContext({
  currency: '',
  changeCurrency: (_: string) => undefined,
  convertToPreferedCurrency: (number: number) => ({} as { currency?: string, number?: number }),
  isLoadingRates: true,
})

export const usePreferedCurrency = () => {
  const router = useRouter()
  const [preferedCurrency, setPreferedCurrency] = useState('')
  const [rates, setRates] = useState({ eur: 0, rub: 0, gel: 0 })
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const load = async () => {
      try {
        const response = await queries.getRates()
        setRates(response.data.data)
      } catch (err) {
        Sentry.captureException(err)
      } finally {
        setIsLoading(false)
      }
    }

    if (router.route === '/') {
      load()
    }
  }, [router])

  useEffect(() => {
    const storedCurrency = Cookies.get('dc_currency')

    if (!storedCurrency) {
      Cookies.set('dc_currency', 'usd')
      setPreferedCurrency('usd')
      return
    }

    setPreferedCurrency(storedCurrency)
  }, [])

  const changeCurrency = (currency: string) => {
    Cookies.set('dc_currency', currency)
    setPreferedCurrency(currency)
  }

  const convertToPreferedCurrency = (number: number) => {
    if (isLoading) return {}

    if (preferedCurrency === 'usd') {
      return {
        number,
        currency: 'USD'
      }
    }

    return {
      number: Math.round(number * rates[preferedCurrency]),
      currency: preferedCurrency.toUpperCase()
    }
  }

  return {
    changeCurrency,
    currency: preferedCurrency,
    convertToPreferedCurrency,
    isLoadingRates: isLoading,
  }
}

const usePreferedCurrencyContext = () => {
  return useContext(PreferedCurrencyContext)
}

export default usePreferedCurrencyContext
