import axios, { AxiosPromise } from 'axios'

import {
  PriceHandlerResponse,
  PriceHandlerQuery,
  RatesHandlerResponse,
  CarsHandlerQuery,
  CarsHandlerResponse,
  BookHandlerBody,
  SendVerificationHandlerBody,
  SubmitReviewHandlerBody,
  BookHandlerResponse,
  SendVerificationHandlerResponse,
  SubmitReviewHandlerResponse,
  AdminLoginHandlerBody,
  AdminLoginHandlerResponse,
  AdminBookingsHandlerQuery,
  AdminBookingsHandlerResponse
} from '../types/api'

const client = axios.create({
  baseURL: '/api',
})

const createUrlWithQuery = (base: string, params?: object) => {
  if (!params || Object.keys(params).length === 0) {
    return base
  }

  const mappedParams = Object.keys(params).map(key => `${key}=${params[key]}`)

  return `${base}?${mappedParams.join('&')}`
}

const getCarPriceForDates = (query: PriceHandlerQuery): AxiosPromise<PriceHandlerResponse> => client.get(createUrlWithQuery('/price', query))
const getRates = (): AxiosPromise<RatesHandlerResponse> => client.get(createUrlWithQuery('/rates'))
const getCars = (query: CarsHandlerQuery): AxiosPromise<CarsHandlerResponse> => client.get(createUrlWithQuery('/cars', query))
const bookCar = (body: BookHandlerBody): AxiosPromise<BookHandlerResponse> => client.post('/book', body)
const sendVerification = (body: SendVerificationHandlerBody): AxiosPromise<SendVerificationHandlerResponse> => client.post('/send-verification', body)
const submitReview = (body: SubmitReviewHandlerBody): AxiosPromise<SubmitReviewHandlerResponse> => client.post('/submit-review', body)
const adminLogin = (body: AdminLoginHandlerBody): AxiosPromise<AdminLoginHandlerResponse> => client.post('/admin-login', body)
const adminBookings = (query: AdminBookingsHandlerQuery): AxiosPromise<AdminBookingsHandlerResponse> => client.get(createUrlWithQuery('/admin-bookings', query))

export const queries = {
  getCarPriceForDates,
  getRates,
  getCars,
  bookCar,
  sendVerification,
  submitReview,
  adminLogin,
  adminBookings,
}

export default client
