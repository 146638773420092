import ObjectId from 'bson-objectid'
import { differenceInDays, isValid, } from 'date-fns'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { TFunction } from 'next-i18next'
import { TestFunction } from 'yup'

export const testFormRange = (t: TFunction): TestFunction<[string, string]> => (value, { createError, path }) => {
  if (!value) {
    return createError({ path, message: t("both_dates") })
  }

  const [start, end] = value
  const [parsedStart, parsedEnd] = [new Date(start), new Date(end)]

  const isValidStart = isValid(parsedStart)
  const isValidEnd = isValid(parsedEnd)

  if (!isValidStart && !isValidEnd) {
    return createError({ path, message: t("start_end_not_valid") })
  }

  if (!isValidStart) {
    return createError({ path, message: t("start_not_valid") })
  }

  if (!isValidEnd) {
    return createError({ path, message: t("end_not_valid") })
  }

  if (differenceInDays(parsedEnd, parsedStart) < 1) {
    return createError({ path, message: t("small_gap") })
  }

  return true
}

export const testPhoneNumber = (t: TFunction): TestFunction<string> => (value, { createError, path }) => {
  if (!value) {
    return createError({ path, message: t("phone_required") })
  }

  if (!isValidPhoneNumber(`+${value}`)) {
    return createError({ path, message: t("phone_not_valid") })
  }

  return true
}

export const testPhoneSimple = (value) => {
  if (!value) {
    return false
  }

  if (!isValidPhoneNumber(`+${value}`)) {
    return false
  }

  return true
}

export const testObjectId: TestFunction<string> = (value) => {
  if (!value) {
    return false
  }

  if (!ObjectId.isValid(value)) {
    return false
  }

  return true
}
