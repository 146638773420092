import cookie from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const useReferrer = () => {
  const router = useRouter()

  useEffect(() => {
    if (router.query.ref && !cookie.get('dc_ref')) {
      const ref = Array.isArray(router.query.ref) ? router.query.ref[0] : router.query.ref

      cookie.set('dc_ref', ref)
    }
  }, [router.query])
}

export default useReferrer
