import '../styles/globals.css'
import '../services/yup'
import { Analytics } from '@vercel/analytics/react'
import { Ubuntu } from 'next/font/google'
import Router from 'next/router'
import { appWithTranslation } from 'next-i18next'
import NProgress from 'nprogress'

import { PreferedCurrencyContext, usePreferedCurrency } from '../hooks/usePreferedCurrency'
import useReferrer from '../hooks/useReferrer'
import 'nprogress/nprogress.css'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const ubuntu = Ubuntu({ weight: ['400', '500', '700'], subsets: ['latin', 'cyrillic'] })

function MyApp({ Component, pageProps }) {
  useReferrer()
  const preferedCurrency = usePreferedCurrency()

  return (
    <main className={ubuntu.className}>
      <PreferedCurrencyContext.Provider value={preferedCurrency}>
        <Analytics />
        <Component {...pageProps} />
      </PreferedCurrencyContext.Provider>
    </main>
  )
}

export default appWithTranslation(MyApp)
